import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import classNames from 'classnames';

import Styles from './MigrationBanner.module.scss';

const MigrationBanner = ({ className }) => {
  const location = window.location.hostname;

  const styles = [
    {
      backgroundColor: '#FFF3CD',
      color: '#856404',
      border: '1px solid #FFEEBA',
    },
    {
      backgroundColor: '#FFD9D9',
      color: '#B30000',
      border: '1px solid #FF9999',
    },
    {
      backgroundColor: '#FCE4EC',
      color: '#D81B60',
      border: '1px solid #F8BBD0',
    },
    {
      backgroundColor: '#FFEFD5',
      color: '#A0522D',
      border: '1px solid #FFDAB9',
    },
    {
      backgroundColor: '#FFF3CD',
      color: '#664D03',
      border: '1px solid #FFECB5',
    },
  ];

  const [currentStyleIndex, setCurrentStyleIndex] = useState(0);

  const handleClick = () => {
    setCurrentStyleIndex((prevIndex) => (prevIndex + 1) % styles.length);
  };

  // Only show the banner for AWS deployments (these URLS contain 'digital')
  if (!location.includes('digital')) {
    return null;
  }

  return (
    <Alert
      className={classNames(className, Styles.alert)}
      style={{ ...styles[currentStyleIndex] }}
      onClick={handleClick}>
      <p>
        Das LMA / GU Dashboard zieht in die Google Cloud und ist nun unter folgendem Link erreichbar:{' '}
        <a href='https://last-mile-analytics.hc.de' className='alert-link' target='_blank'>
          last-mile-analytics.hc.de
        </a>{' '}
        .
      </p>
      <p>
        Vorrübergehend wird <i>last-mile-analytics.hermesgermany.digital</i> weiterhin nutzbar bleiben und zum{' '}
        <b>31.12.2024</b> abgeschaltet. <b>Bitte an die Lesezeichenaktualisierung denken!</b> Nutzereinstellungen (wie
        z.B. angelegte KPI Kacheln) werden mit Stand xx (tbd) einmalig übernommen und anschließend nicht zwischen neuer
        und alter URL synchronisiert.
      </p>
      {`Current setting: ${currentStyleIndex}`}
    </Alert>
  );
};

export default MigrationBanner;
